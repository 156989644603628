<template>
  <div class="wrapper">
    <Header />
    <main class="main center" id="main">
      <Placeholder
        :placeholder-text="
          authLink && !authLink.success
            ? 'Your link is expired, please, contact us'
            : 'Loading...'
        "
      />
    </main>
    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/Footer";
import Header from "@/components/Header";
import Placeholder from "@/components/placeholder";
import { authLink, checkRecaptcha } from "@/components/graphQL/userAuth.gql";
import Vue from "vue";
import { VueReCaptcha } from "vue-recaptcha-v3";

Vue.use(VueReCaptcha, { siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY });

export default {
  name: "UpdatingLink",
  components: {
    Header,
    Footer,
    Placeholder,
  },
  apollo: {
    authLink: {
      query: authLink,
      variables() {
        return {
          inviteToken: this.link,
        };
      },
      skip() {
        return !this.link;
      },
      result(result) {
        if (result.data.authLink.success) {
          localStorage.setItem("Mode", "client");
          localStorage.setItem("authToken", result.data.authLink.token);
          result.data.authLink.broker !== null
            ? this.$router.push({
                name: "brokerEdit",
                params: { id: result.data.authLink.broker.id },
              })
            : this.$router.push({ name: "brokerCreate" });
        }
      },
    },
    checkRecaptcha: {
      query: checkRecaptcha,
      variables() {
        return {
          token: this.recaptchaToken,
        };
      },
      skip() {
        return !this.recaptchaToken;
      },
      result(result) {
        this.link = result.data.checkRecaptcha ? this.$route.query.token : null;
      },
    },
  },
  data() {
    return {
      link: "",
      recaptchaToken: "",
    };
  },
  created() {},
  mounted() {
    this.recaptcha();
  },
  methods: {
    async recaptcha() {
      await this.$recaptchaLoaded();
      const token = await this.$recaptcha("login");
      this.recaptchaToken = token;
    },
  },
};
</script>
